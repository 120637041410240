import { FC } from 'react';

import classNames from 'classnames';

import BrandOutlineWhite from '../../resources/images/brand/cru-brand-outline-white.svg';

export type BrandProps = {
    size: string;
    loading?: 'lazy' | 'eager';
    className?: string;
};

export const Brand: FC<BrandProps> = ({ size, loading, className }) => {
    const src = BrandOutlineWhite;

    const componentClasses = classNames('c-brand', className);
    return (
        <img
            className={componentClasses}
            src={src}
            alt="CRU"
            height={size}
            width={size}
            loading={loading}
        />
    );
};
