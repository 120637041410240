export default {
    confirmBtn: 'Yes',
    cancelBtn: 'No',
    expiryText:
        '<strong>Part or all of your subscription is due to expire in {0}.</strong> Would you like to make changes to your subscription on renewal?',
    modal: {
        acceptedText:
            'Thank you. If you are not the account holder, please contact them to discuss your requirements.',
        declinedText:
            'Thank you. Please provide us with feedback on the service.',
        btnText: 'OK',
    },
};
