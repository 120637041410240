export type Config = {
    REACT_APP_AUTH0_DOMAIN: string;
    REACT_APP_AUTH0_CLIENT_ID: string;
    REACT_APP_AUTH0_AUDIENCE: string;
    REACT_APP_GA_MEASUREMENT_ID: string;
    REACT_APP_TAG_MANAGER_ID: string;
    REACT_APP_API_URL: string;
    REACT_APP_TOUR_BOOKING_URL: string;
    REACT_APP_AG_GRID_LICENSE: string;
    APPLICATIONINSIGHTS_CONNECTION_STRING: string;
    REACT_APP_FF_ENABLE_SNOWPLOW: string;
    REACT_APP_SNOWPLOW_COLLECTOR_URL: string;
    REACT_APP_SNOWPLOW_APP_ID: string;
    REACT_APP_FF_SHOW_CHAT_AI: string;
    REACT_APP_FF_SHOW_ARTICLE_TRANSLATION: string;
    REACT_APP_FF_SHOW_ARTICLE_TRANSLATION_SUMMARY: string;
    REACT_APP_FF_SHOW_ARTICLE_TRANSLATION_TRANSLATE: string;
    REACT_APP_FF_ENABLE_HOTJAR: string;
};

const OVERRIDE_DENYLIST = [
    'REACT_APP_AUTH0_AUDIENCE',
    'REACT_APP_AUTH0_DOMAIN',
    'REACT_APP_AUTH0_CLIENT_ID',
];

export function getConfigValue<K extends keyof Config>(name: K): string {
    const userConfig = new URLSearchParams(window.location.search);
    const envVar = window._env_[name];

    if (userConfig.has(name) && !OVERRIDE_DENYLIST.includes(name)) {
        return userConfig.get(name) as string;
    }

    if (!envVar) {
        throw new Error(`Environment variable ${name} is not set`);
    }

    return envVar;
}
