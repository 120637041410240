import {
    FC,
    ReactNode,
    createContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import debounce from 'lodash.debounce';

type WindowSize = {
    width: number;
    height: number;
};

export type DeviceContextInterface = {
    smallDevice: boolean;
    windowSize?: WindowSize;
};

export const DeviceContext = createContext<DeviceContextInterface>({
    smallDevice: false,
    windowSize: undefined,
});

type DeviceContextProviderProps = { children?: ReactNode };

const getWindowSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
});

const DeviceContextProvider: FC<DeviceContextProviderProps> = ({
    children,
}) => {
    const [windowSize, setWindowSize] = useState<WindowSize>(getWindowSize);

    const smallDevice = windowSize.width < 1260;

    useEffect(() => {
        // Debounce to avoid the function fire multiple times
        const handleResizeDebounced = debounce(function handleResize() {
            setWindowSize(getWindowSize());
        }, 400);

        window.addEventListener('resize', handleResizeDebounced);
        return () =>
            window.removeEventListener('resize', handleResizeDebounced);
    }, []);

    const contextValue = useMemo(
        () => ({ smallDevice, windowSize }),
        [smallDevice, windowSize]
    );

    return (
        <DeviceContext.Provider value={contextValue}>
            {children}
        </DeviceContext.Provider>
    );
};

export default DeviceContextProvider;
