export enum QueryKeys {
    articlesList = 'articlesList',
    searchArticlesList = 'searchArticlesList',
    priorityTagList = 'priorityTagList',
    useAccount = 'useAccount',
    useAlerts = 'useAlerts',
    useArticles = 'useArticles',
    usePriceArticles = 'usePriceArticles',
    useFeaturedArticles = 'useFeaturedArticles',
    useArticle = 'useArticle',
    useArticleSummarisation = 'useArticleSummarisation',
    useArticleTranslation = 'useArticleTranslation',
    useArticleSummaryTranslation = 'useArticleSummaryTranslation',
    useArchiveSummaries = 'useArchiveSummaries',
    useBookmarkedArticles = 'useBookmarkedArticles',
    useDownloads = 'useDownloads',
    useDownloadsMenu = 'useDownloadsMenu',
    useGenericPage = 'useGenericPage',
    getHeader = 'getHeader',
    useHelp = 'useHelp',
    useNotifications = 'useNotifications',
    usePrice = 'usePrice',
    usePrices = 'usePrices',
    useGetCookie = 'useGetCookie',
    emailPreferences = 'emailPreferences',
    usePriceBookmarks = 'usePriceBookmarks',
    savedSearches = 'savedSearches',
    allSavedSearches = 'allSavedSearches',
    latestSavedSearches = 'latestSavedSearches',
    useRelatedArticles = 'useRelatedArticles',
    getTours = 'getTours',
    getMedia = 'getMedia',
    redirectToUrl = 'redirectToUrl',
    getChats = 'getChats',
    getCampaigns = 'getCampaigns',
}

export type ValidQueryKey = `${QueryKeys}`;
