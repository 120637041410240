import { Pages } from '../../../domain/Pages';

export default {
    links: [
        {
            text: 'Privacy Policy',
            href: 'https://www.crugroup.com/privacy-policy',
        },
        {
            text: 'General Terms & Conditions',
            href: 'https://www.crugroup.com/terms-and-conditions',
        },
        {
            text: 'Licence Terms & Conditions',
            path: Pages.terms,
        },
    ],
    copyright: '© 2024 CRU International Ltd. All rights reserved',
};
